/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
.section {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  overflow-x: hidden;
}
.section::before,
.section::after {
  content: '';
  display: block;
}
.section-padding-none::after,
.section-padding-none::before {
  margin: 0 !important;
}
.section-padding-none .image_container img {
      margin-bottom: 0;
    }

.section {
  .ce_bs_gridSeparator {
    .image_container {
      //padding-top: 4rem;
    }
  }
}
.bg-color-secondary-light.partner.section.last:after{
  margin-top: 100px !important;
}
.section--140:before {
  margin-bottom: 42.5px !important;
}
.section--140::after {
  margin-top: 42.5px !important;
}
.section-padding-bottom:before {
  margin: 0 !important;
}
.section-slider.section-padding-bottom {
  margin-top: -17px;
}
.section.last {
  border-bottom: none !important;
}
.partner.section.last.bg-color-secondary-light:after {
  margin-bottom: 0 !important;
}
.partner .row >div {
  padding-left: 15px;
  h2 {
    margin-bottom: 0;
  }
  p.medium{
    margin-top: 0;
  }
}
.section-border-top-none {
  border-top: none;
}
.section-border-bottom-none {
  border-bottom: none;
}
.section-zitat.last::after,
.partner.section.last:after {
  margin: 3.75rem !important;
  border-bottom: 1px solid transparent !important;
}
.section-padding-bottom:before {
  margin: 0;
}
.section--group:before {
  margin-bottom: 5.75rem !important;
}
.DSGVU .inside {
  max-width: 990px;
  margin-bottom: $section-margin-bottom;
}
.section--group:after {
  margin-top: rem(95px) !important;
}
.section--group .inside {
  max-width: 850px;
  .btn--center {
    margin-top: 42px;
  } 
  .headline-1{
    margin-bottom: 22px;
  }
}
section.first {
  .ce_bs_gridSeparator .image_container {
    //padding-top: 8rem;
  }
}
section.first:before {
  margin-bottom: rem(110px) !important;
}
.section .first::before {
  margin-bottom: 5.75rem !important;
}
.ce_bs_gridStart.bg-color-primary-light.row {
  min-height: auto;
  &>div {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 60px;
    padding-bottom: 70px;

    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
      margin-top: 18px;
    }
}
}
.section::before {
  margin-bottom: $section-margin-top;
}

.section::after {
  margin-top: $section-margin-bottom;
}
.section.last::after {
  margin: 0 !important;
}
.section--dark {
  background-color: $color-black;
}
.section--light {
  background-color: $color-black-60;
}

.bg-color-grey {
  background-color: $color-black;
  color: $white;
}
section.first::before {
  margin: 0;
}

.section--kopfbild.first::before{
  margin-bottom: .5rem !important;
}

.grid__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-self: start;
  gap:3.75rem;
  .grid__item--order {
    order:1;
  }
  .grid__item--ende{
    margin: 0 auto;
  }
  .grid__item--image {
    display: block;
    left: 0;
    aspect-ratio: 1/0;

    img {
      width: 1900px !important;
      object-fit: cover;
    }
  }
  .headline_wrapper {
   margin-top: 0;
    max-width: 594px;
    padding-left: 15px;
    padding-right: 15px;
    .display-2 ,h2 {
      color:$color-primary-dark;
    }
  }
  .text__wrapper {
    max-width: 594px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) {
  .section--kopfbild.first::before{
    margin-bottom: $element-margin-bottom !important;
  }
  .section {
  }
  .grid__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:rem(58px);
    .grid__item--order {
      order:1;
    }
    .grid__item--ende{
      margin: 0 auto;
    }
    .grid__item--image {
      display: block;
      left: 0;
      aspect-ratio: 1/0;
  
      img {
        width: 1900px !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
    .headline_wrapper {
      margin-top: rem(77px);
      max-width: 482px;
      .display-2 ,h2 {
        color:$color-primary-dark;
      }
    }
    .text__wrapper {
      max-width: 482px;
    }
  }
}
@media only screen and (min-width: 1200px) {
  section.first::before {
    margin-bottom: 7.75rem;
  }
  .section--kopfbild.first::before{
    margin-bottom: $element-margin-bottom;
  }
}


@media (max-width:990px) {

  .navlist__item--contact .navlist__link {
    line-height: 34px !important;
    padding-bottom: 0!important;
    padding-top: 0!important;
}
  .m-t-5 {
    margin-top: 2.5rem;
  }
  .ce_bs_gridStart.change.first.row {
    flex-wrap: wrap-reverse;
}
  .margin_first_element.col-12.col-lg-6 {

    .image_container{
      margin-top: 2.5rem;
    }
    
}
  
  
}