.image__wreapper--group {
    position: relative;
    img{
      margin-bottom: 0;
      border: 13px solid $color-white;
      box-sizing: border-box;
    }
    .group__images--two:nth-child(2){
     
    .group_image {
      position: absolute;
      top: 112px;
      right: 190px;
      z-index: -1;
    }
    }
    .group__images--three:nth-child(2){
      .group_image {
        position: absolute;
        top: 219;
        right: 190px;
        z-index: -1;
    }
    }
   
  
}

@media (max-width:990px) {
    .image__wreapper--group {
        position: relative;
        img{
          margin-bottom: 0;
          border: 13px solid $color-white;
          box-sizing: border-box;
        }
        .group__images--two:nth-child(2){
         
        .group_image {
          position: absolute;
          top: 112px;
          right: 0;
          z-index: -1;
        }
        }
        .group__images--three:nth-child(2){
          .group_image {
            position: absolute;
            top: 219;
            right: 0;
            z-index: -1;
        }
        }
       
      
    }
}

@media (max-width:450px) {
    .image__wreapper--group {
        position: relative;
        img{
          margin-bottom: 0;
          border: 13px solid $color-white;
          box-sizing: border-box;
        }
        .group__images--two:nth-child(2){
         
        .group_image {
          position: inherit;
          top: 350px;
          right: 0;
          z-index: -1;
        }
        }
        .group__images--three:nth-child(2){
          .group_image {
            position: inherit;
            top: 350;
            right: 0;
            z-index: -1;
        }
        }
       
      
    }
}