.bg-color-white p,
.bg-color-white li {
  color:$color-black-80 ;
}
.bg-color-primary p > a{
  color: $color-white;
  text-decoration: none;
  cursor: pointer;
}
.bg-color-white p > a {
  color: $color-black-80;
  text-decoration: none;
  cursor: pointer;
}
.bg-color-primary{
  color:$color-white;
}
.bg-color-primary-super-light {
  //border:none !important;
}
.bg-color-primary-super-light p,
.bg-color-primary-super-light li  {
  color:$color-black-70 !important ;
}
p {
  font-family: $font-medium-font-family;
  font-size: rem($font-medium-font-size);
  line-height: rem($font-medium-line-height);
  font-weight: $font-small-font-weight;
  margin-bottom: rem($element-margin-bottom);
  
}
.xxlarge {
  font-family: $font-xxlarge-font-family;
  font-size: 1.3rem;
  line-height: 2.2rem;
  font-weight: $font-xxlarge-font-weight;
  margin-bottom: rem($element-margin-bottom);
}

.large-bold {
  font-family: $font-xxlarge-bold-font-family;
  font-size: rem($font-xlarge-bold-font-size);
  line-height: rem($font-xlarge-bold-line-height);
  font-weight: $font-xlarge-bold-font-weight;
  margin-bottom: rem($element-margin-bottom);
}

.medium-bold {
  font-family: $font-medium-bold-font-family;
  font-size: rem($font-medium-bold-font-size);
  line-height: rem($font-medium-bold-line-height);
  font-weight: $font-medium-bold-font-weight;
  margin-bottom: rem($element-margin-bottom);
}

.medium {
  font-family: $font-medium-font-family;
  font-size: rem($font-medium-font-size);
  line-height: rem($font-medium-line-height);
  font-weight: $font-medium-font-weight;
  margin-bottom: rem($element-margin-bottom);
}

.small-bold {
  font-family: $font-small-bold-font-family;
  font-size: rem($font-small-bold-font-size);
  line-height: rem($font-small-bold-line-height);
  font-weight: $font-small-bold-font-weight;
  margin-bottom: rem($element-margin-bottom);
}
.xsmall {
  font-family: $font-xsmall-font-family;
  font-size: rem($font-xsmall-font-size);
  line-height: rem($font-xsmall-line-height);
  font-weight: $font-xsmall-font-weight;
  margin-bottom: rem($element-margin-bottom);
}
.xxsmall {
  font-family: $font-xxsmall-font-family;
  font-size: rem($font-xxsmall-font-size);
  line-height: rem($font-xxsmall-line-height);
  font-weight: $font-xxsmall-font-weight;
  margin-bottom: rem($element-margin-bottom);
}
.xxsmall-bold {
  font-family: $font-xxsmall-bold-font-family;
  font-size: rem($font-xxsmall-bold-font-size);
  line-height: rem($font-xxsmall-bold-line-height);
  font-weight: $font-xxsmall-bold-font-weight;
  margin-bottom: rem($element-margin-bottom);
}
p.lead {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2em;
}

p.small,
small {
  font-size: 0.888rem;
  line-height: 1.25em;
  margin-bottom: $element-margin-bottom;
}
strong {
  font-weight: 700;
}

.col-12 > p {
  margin-top: rem(40px); // resets margin of firt P
}
.max-text-width{
  max-width: 700px;
}

@media (min-width: 1200px) {
  .bg-color-white p,
  .bg-color-white li {
    color:$color-black-80 ;
  }
  .bg-color-primary{
    color:$color-white;
  }
  p {
    font-family: $font-medium-font-family;
    font-size: rem($font-medium-font-size);
    line-height:rem($font-medium-line-height);
    font-weight: $font-medium-font-weight;
    
  }
 
  p.lead {
    font-size: 1.4375rem;
    line-height: 1.217em;
  }
}
