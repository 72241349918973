



  .stacked {
    display: grid;
  }
  
  .stacked > * {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  
  .card_modal {
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(317px, 1fr));
  }
  
  .card_item {
    aspect-ratio: 1 / 1.1;
    /* border: 3px solid hotpink; */
  }
  
  .item_kachel {
    align-self: end;
    margin: 0.5rem 2rem 2rem;
    //width: 307px;
    height: 187px;
    padding: 0.5rem;
    box-shadow: 0px 2px 8px rgb(40 41 61 / 4%), 0px 16px 24px rgb(96 97 112 / 16%);
    background: rgb(256,256,256,.85);
     text-align: center;
     &>div {
                display: flex;
                justify-content: center;
                opacity: 1;
            }
     h2 {
       margin-top: rem(23px);
       margin-bottom: 0;  
       opacity: 1;
       color:$color-primary-dark;
     }       
    .kachel--subtitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0.75rem;
        /* or 162% */
        opacity: 1;
        text-align: center;

        /* primary-dark */

        color: #3A5139;
    }
  }
  
  
  .item_image {
    width: 100%;
    aspect-ratio: 1 / 1.25;
    object-fit: cover;
  }
  
   @media (min-width:1399px){
    .item_kachel {
        margin-bottom: 2.5rem !important;
    }
   }
  @media (min-width: 60em) {
    .item_image{
      aspect-ratio: 1 / 1.1;
    }
    .item_kachel {
        margin-bottom: 2rem;
    }
  }
  

  @media (max-width:991px) {
    // .item_image{
    //     aspect-ratio: 1 / 1.2;
    //   }
  }
  @media (max-width:770px) {
    // .item_image{
    //     aspect-ratio: 1 / 1;
    //   }
      .item_kachel {
          margin-bottom: .5rem;
      }  
  }

  @media (max-width:520px) {
    // .item_image{
    //     aspect-ratio: 1 / 1.15;
    //   }
    
  }
  
  @media (max-width:466px) {
    .item_kachel {
        margin-bottom: 2rem;
    }  
    
  }
  @media (max-width:385px) {
    .item_kachel {
        margin-bottom: 1rem;
    }  
    
  }
  
  @media (max-width:992px){
    .card_modal {
      .card_item {
        aspect-ratio: 0;
        /* border: 3px solid hotpink; */
      }
    .item_image {
        aspect-ratio: 0 ;
    }
  }
    .item_kachel {
      align-self: start;
      margin: 0.5rem 2rem 2rem;
      margin-top: 10%;
    }
  }
  @media (max-width: 768px){
    .item_kachel {
      align-self: start;
      margin: 20% 2rem 2rem;
      margin-top: 30px;
  }
  }
  @media (max-width:760px){
    .item_kachel {
      align-self: start;
      margin: auto 2rem;
      margin-top: 100;
    }
  }
  @media (max-width:560px){
    .item_kachel {
      align-self: start;
      margin: auto 2rem;
      margin-top: 80;
    }
  }

  @media (max-width:490px){
  .item_kachel {
    align-self: start;
    margin: 20% 2rem 2rem;
    margin-top: 70;
}
}
@media (max-width:420px){
  .item_kachel {
    align-self: start;
    margin: 20% 2rem 2rem;
    margin-top: 40;
}
}

@media (max-width:380px){
  .item_kachel {
    align-self: start;
    margin: 20% 2rem 2rem;
    margin-top: 30;
}
}

@media (max-width:320px){
  .item_kachel {
    align-self: start;
    margin: 20% 2rem 2rem;
    margin-top: 20;
}
}


