.grid__wrapper--card{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(342px, 1fr));
    gap: 30px;
    .grid__item {
      height: auto;
      padding: 29px;
      /* border: 1px dashed; */
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);
      box-sizing: border-box;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 1fr;
      .item_image{
        aspect-ratio: 0;
        img {
          margin-bottom: 0;
          max-height: 228;
        }
      }
     h4 {
       margin-top: 24px;
       color:$color-primary-dark;
       font-size: 1.25rem;
       line-height: 1.7rem;
     }
     p {
       margin-top: 8px;
       max-height: 134px;
       overflow: hidden;
     }
    }
  }

  @media (max-width:770) {
    .grid__wrapper--card{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 30px;
        .grid__item {
          height: auto;
          padding: 29px;
          /* border: 1px dashed; */
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);
          box-sizing: border-box;
          border-radius: 5px;
          display: grid;
          grid-template-columns: 1fr;
          .item_image{
            aspect-ratio: 0;
            min-width: 312px;
            img {
              margin-bottom: 0;
              max-height: 228;
            }
          }
         h4 {
           margin-top: 24px;
           font-size: 1.25rem;
           line-height: 1.7rem;
           color:$color-primary-dark;
         }
         p {
           margin-top: 8px;
           max-height: 134px;
           overflow: hidden;
         }
        }
      }
      
  }