/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/
.link {
  text-decoration: underline;

  &.link--footer,
  &.link--footer:visited {
    color: $white;
    font-weight: 400;
    text-decoration: none;
  }
  &.link--footer:hover,
  &.link--footer:focus {
    color: $white;
    cursor: pointer;
    text-decoration: underline;
  }
}

.mail--link
  {
    color: inherit;
    text-decoration-line: none;
}

.link__text--primary{
  text-decoration: underline !important;
  font-weight: 700;
  color:$color-primary-dark;
}
