/* -------------------------------------------------------------
//  Images
// -----------------------------------------------------------*/
img {
  height: auto;
  margin-bottom: $element-margin-bottom;
  max-width: 100%;
}
.ce_bs_gridSeparator .image_container {
  margin: 0;
  text-align: end;
}

figure.image_container {
  margin: 0;
}
.kopfbild {
 // display: flex;
 // justify-content: center;
 margin: 0 auto;
  img {
   // max-width: 1440px;
    //height: 600px;
    margin: 0;
    width: 100%;
    object-fit: cover;
  }
}


@media (max-width:990px) {
  .ce_bs_gridSeparator .image_container {
    margin: 0;
    text-align: start;
}
}

.icon-instagram {
  margin-bottom: 0;
    margin-right: 24px;
}