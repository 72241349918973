/* -------------------------------------------------------------
//  Basics
// -----------------------------------------------------------*/
body {
  background-color: $white;
  color: $color-primary;
  font-family: 'Roboto', Arial;
  font-size: 16px;
  line-height: 1.3em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  padding-top: 86px;
}



.container-fluid{
  padding-left: 0 !important;
  padding-right: 0 !important;
}