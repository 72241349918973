/* -------------------------------------------------------------
//  Slider
// -----------------------------------------------------------*/
.glide__bullets {
    position: absolute;
    bottom: -2rem !important;
}
ul.glide__slides.cols_4 {
    justify-content: center;
    display: flex;
   // align-items: center;
    white-space: nowrap;
    text-align: center;
}
.slider__text{
    max-width: 820px;
    display: block;
    margin: 0 auto;
    .headline-1{
        margin-top: 0;
        margin-bottom: 0px;
    }
}
.glide__bullet{
    width: 10px !important;
    height: 10px !important;
    background-color: $color-primary-bg-light !important;
    border: none !important;
    &:hover {
        background-color:$color-white !important;
    }
}
.glide__bullet--active {
    background-color: $color-white !important;
}

.glide__arrows {
    -webkit-touch-callout: none;
    user-select: none;
    position: relative;
    bottom: -2rem;
}
.glide__arrow {
    border: none !important;
    box-shadow: none !important;
    top: -5px !important;
    color:$color-primary-bg-light !important;

    &:hover {
        color:$color-white !important;
    }
}
.glide__arrow--right {
    right: 47% !important;
   
}

.glide__arrow--left {
    left: 47% !important;
}
.icon-arrow--left::before {
    transform: rotatey(180deg) !important;
    position: absolute;
    top: -3;
    right:40px;
    font-size: 26px;
}
.glide__arrow--right.icon-arrow::before {
    position: absolute;
    top: -3;
    left: 40px;
    font-size: 26px;
}
li.glide__slide.slider_item.stacked .item_image {
    margin-top: 0;
}
.slider_modal {
        display: grid;
        margin-bottom: 3rem;
        .glide__bullets {
            position: absolute;
            bottom: 0rem !important;
        }
        .glide__arrows {
            -webkit-touch-callout: none;
            user-select: none;
            position: relative;
            bottom: 0rem;
        }
        .glide__bullet{
            width: 10px !important;
            height: 10px !important;
            background-color: $color-black-30 !important;
            border: none !important;
            box-shadow: none !important;
            &:hover {
                background-color:$color-primary !important;
            }
        }
        .glide__bullet--active {
            background-color: $color-primary !important;
            color:$color-primary !important;
            border-color: $color-primary !important ;
            //box-shadow: inset 0 0 0 3px $color-primary !important;
        }
        
        
        .glide__arrow {
            border: none !important;
            box-shadow: none !important;
            top: -5px !important;
            color:$color-primary !important;
        
            &:hover {
                color:$color-primary-dark !important;
            }
        }
        .item_image {
            .image_container img {
                width: 1900px !important;
                height: 600px;
                object-fit: cover;

            }
        }
        
          
        .item_kachel--slider {
            align-self: center;
            display: grid;
            text-align: center;
            justify-self: center;
            min-width: 418px;
            max-height: 250px;
            min-height: 200px;
            padding: 0.5rem;
            box-shadow: 0px 2px 8px rgb(40 41 61 / 4%), 0px 16px 24px rgb(96 97 112 / 16%);
            background: rgb(256,256,256,.8);
             &>div {
                        display: flex;
                        justify-content: center;
                        padding: 0 50px;
                        opacity: 1;
                    }
             .display-2 {
               //margin-top: rem(23px);
               //margin-bottom: 0;  
               opacity: 1;
               max-width: 420px;
               width: min-content;
                text-align: center;
                justify-content: center;
                align-items: center;
                align-content: center;
                margin: 0 auto;
                padding-top: rem(23px);

             }       
            .kachel--subtitle {
                font-family: "Roboto";
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 0.75rem;
                opacity: 1;
                /* or 162% */
        
                text-align: center;
        
                /* primary-dark */
        
                color: #3A5139;
            }
            .btn {
                opacity: 1;
            }
          }
          
          
          .item_image {
            width: 100%;
            aspect-ratio: 0;
            object-fit: cover;
          }
    }


@media (max-width:992px){
    .slider_modal .item_image {
        aspect-ratio: 0;
    margin-top: 2rem;
        .image_container img {
            width: 1900px !important;
            height: 475px;
            object-fit: cover;
        }
    }
}    