/* -------------------------------------------------------------
//  Typo
// -----------------------------------------------------------*/
.bg-color-primary .display-2,
.bg-color-primary h2{
  color:$color-white;
}
.bg-color-white .display-2,
.bg-color-white h2 {
  color:$color-primary-dark;
}
.display-1 {
  font-family: $font-mobile-display-1-font-family;
  font-size: rem($font-mobile-display-1-font-size);
  font-weight: $font-mobile-display-1-font-weight;
  line-height: rem($font-mobile-display-1-line-height);
  margin-top: rem($spacing-display1-mobile-top);
  margin-bottom: rem($spacing-display1-mobile-bottom);
}
.display-2 {
  font-family: $font-mobile-display-2-font-family;
  font-size: rem($font-mobile-display-2-font-size);
  font-weight: $font-mobile-display-2-font-weight;
  line-height: rem($font-mobile-display-2-line-height);
  margin-top: 0;
  margin-bottom: rem($spacing-display2-mobile-bottom);
}

.headline-1 {
  font-family: $font-mobile-headline-1-font-family;
  font-size: rem($font-mobile-headline-1-font-size);
  font-weight: $font-mobile-headline-1-font-weight;
  line-height: rem($font-mobile-headline-1-line-height);
  margin-top: rem($spacing-headline-1-mobile-top);
  margin-bottom: rem($spacing-headline-1-mobile-bottom);
  
}

h1 {
  font-family: $font-mobile-h1-font-family;
  font-size: rem($font-mobile-h1-font-size);
  font-weight: $font-mobile-h1-font-weight;
  line-height: rem($font-mobile-h1-line-height);
  margin-top: rem($spacing-h1-mobile-top);
  margin-bottom: rem($spacing-h1-mobile-bottom);
  color:$color-primary-dark;
}

h2 {
  font-family: $font-mobile-h2-font-family;
  font-size: rem($font-mobile-h2-font-size);
  font-weight: $font-mobile-h2-font-weight;
  line-height: rem($font-mobile-h2-line-height);
  margin-top: rem($spacing-h2-mobile-top);
  margin-bottom: rem($spacing-h2-mobile-bottom);
}

h3 {
  font-family: $font-mobile-h3-font-family;
  font-size: rem($font-mobile-h3-font-size);
  font-weight: $font-mobile-h3-font-weight;
  line-height: rem($font-mobile-h3-line-height);
  margin-top: rem($spacing-h3-mobile-top);
  margin-bottom: rem($spacing-h3-mobile-bottom);
}

h4 {
  font-family: $font-mobile-h4-font-family;
  font-size: rem($font-mobile-h4-font-size);
  font-weight: $font-mobile-h4-font-weight;
  line-height: rem($font-mobile-h4-line-height);
  margin-top: rem($spacing-h4-mobile-top);
  margin-bottom: rem($spacing-h4-mobile-bottom);
}

h5 {
  font-family: $font-mobile-h5-font-family;
  font-size: rem($font-mobile-h5-font-size);
  font-weight: $font-mobile-h5-font-weight;
  line-height: rem($font-mobile-h5-line-height);
  margin-top: rem($spacing-h5-mobile-top);
  margin-bottom: rem($spacing-h5-mobile-bottom);
}

h6 {
  font-family: $font-mobile-h6-font-family;
  font-size: rem($font-mobile-h6-font-size);
  font-weight: $font-mobile-h6-font-weight;
  line-height: rem($font-mobile-h6-line-height);
  margin-top: rem($spacing-h6-mobile-top);
  margin-bottom: rem($spacing-h6-mobile-bottom);
}

@media (min-width: 1200px) {
  .bg-color-primary .display-2,
  .bg-color-primary h2{
    color:$color-white;
  }
  .bg-color-white .display-2,
  .bg-color-white h2 {
    color:$color-primary-dark;
  }
  .display-1 {
    font-family: $font-desktop-display-1-font-family;
    font-size: rem($font-desktop-display-1-font-size);
    font-weight: $font-desktop-display-1-font-weight;
    line-height: rem($font-desktop-display-1-line-height);
    margin-top: rem($spacing-display1-desktop-top);
    margin-bottom: rem($spacing-display1-desktop-bottom);
  }
  .display-2 {
    font-family: $font-desktop-display-2-font-family;
    font-size: rem($font-desktop-display-2-font-size);
    font-weight: $font-desktop-display-2-font-weight;
    line-height: rem($font-desktop-display-2-line-height);
    margin-top: 0;
    margin-bottom: rem($spacing-display2-desktop-bottom);
  }
  .headline-1 {
    font-family: $font-desktop-headline-1-font-family;
    font-size: rem($font-desktop-headline-1-font-size);
    font-weight: $font-desktop-headline-1-font-weight;
    line-height: rem($font-desktop-headline-1-line-height);
    margin-top: rem($spacing-headline-1-desktop-top);
    margin-bottom: rem($spacing-headline-1-desktop-bottom);
  }
  h1 {
    font-family: $font-desktop-h1-font-family;
    font-size: rem($font-desktop-h1-font-size);
    font-weight: $font-desktop-h1-font-weight;
    line-height: rem($font-desktop-h1-line-height);
    margin-top: rem($spacing-h1-desktop-top);
    margin-bottom: rem($spacing-h1-desktop-bottom);
  }
  h2 {
    font-family: $font-desktop-h2-font-family;
    font-size: rem($font-desktop-h2-font-size);
    font-weight: $font-desktop-h2-font-weight;
    line-height: rem($font-desktop-h2-line-height);
    margin-top: rem($spacing-h2-desktop-top);
    margin-bottom: rem($spacing-h2-desktop-bottom);
  }
  h3 {
    font-family: $font-desktop-h3-font-family;
    font-size: rem($font-desktop-h3-font-size);
    font-weight: $font-desktop-h3-font-weight;
    line-height: rem($font-desktop-h3-line-height);
    margin-top: rem($spacing-h3-desktop-top);
    margin-bottom: rem($spacing-h3-desktop-bottom);
  }
  h4 {
    font-family: $font-desktop-h4-font-family;
    font-size: rem($font-desktop-h4-font-size);
    font-weight: $font-desktop-h4-font-weight;
    line-height: rem($font-desktop-h4-line-height);
    margin-top: rem($spacing-h4-desktop-top);
    margin-bottom: rem($spacing-h4-desktop-bottom);
  }
  h5 {
    font-family: $font-desktop-h5-font-family;
    font-size: rem($font-desktop-h5-font-size);
    font-weight: $font-desktop-h5-font-weight;
    line-height: rem($font-desktop-h5-line-height);
    margin-top: rem($spacing-h5-desktop-top);
    margin-bottom: rem($spacing-h5-desktop-bottom);
  }
  h6 {
    font-family: $font-desktop-h6-font-family;
    font-size: rem($font-desktop-h6-font-size);
    font-weight: $font-desktop-h6-font-weight;
    line-height: rem($font-desktop-h6-line-height);
    margin-top: rem($spacing-h6-desktop-top);
    margin-bottom: rem($spacing-h6-desktop-bottom);
  }
}

// .col-12 > h2,
// .col-12 > h3,
// .col-12 > h4,
// .col-12 > h5,
// .col-12 > h6 {
//   margin-top: 0; // resets margin of firt headline
// }
