/* -------------------------------------------------------------
//  Button
// -----------------------------------------------------------*/
.bg-color-primary {
  .btn--secondary {
    border: 2px solid;
    color: $color-white;
    background: transparent;
    max-width: 148px;
    text-align: center;
    margin-top: rem(40);
   
  }
}

.bg-color-white {
  .btn--secondary {
    border: 2px solid;
    color: $color-black-70;
    background: transparent;
    max-width: 148px;
    text-align: center;
    margin-top: rem(40);
   
  }
}
.item_kachel {
  .btn--secondary {
    border: 2px solid;
    color: $color-black-70;
    background: transparent;
    min-width: 148px;
    max-width: fit-content;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 0;
  }
}
.item_kachel--slider {
  .btn--secondary {
    border: 2px solid;
    color: $color-black-70;
    background: transparent;
    min-width: 148px;
    max-width: fit-content;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 0;
  }
}
a.btn.btn--primary.btn--icon {
  display: flex;
}
.btn {
  cursor: pointer;
  display: block;
  font-family: $font-button-lead-font-family;
  font-size: rem($font-button-lead-font-size);
  font-weight: $font-button-lead-font-weight;
  outline: none;
  margin-bottom: $element-margin-bottom;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  
  display: grid;
  align-items: center;
  align-self: center;
  text-align: center;
  align-items: center;

  line-height: 32px;
  max-height: 48px;

  width: max-content;
  opacity: 1;
}

.btn--primary {
  background-color: $color-primary-dark;
  border: 2px solid transparent;
  color: $color-white;
  min-width: fit-content;
  opacity: 1;
}
.btn--submit,
.btn--primarysmall {
  padding: 5px 15px;
}
.btn--primary:hover,
.btn--primary:focus,
.btn--primarysmall:hover,
.btn--primarysmall:focus,
.btn--submit:hover,
.btn--submit:focus {
  background-color: $color-primary;
  border: 2px solid $color-primary-dark;
  text-decoration: none;
}
.btn--secondary {
  background-color: $color-secondary;
  border: 2px solid transparent;
  color: $color-primary ;
}
.btn--secondarysmall {
  padding: 5px 15px;
}

.btn--secondary:hover,
.btn--secondary:focus {
  background-color: $color-secondary;
  border: 2px solid $color-secondary-dark;
  text-decoration: none;
  color : $color-black-90;
}
a.btn--icon[href$=".pdf"]::before {
  font-family: "luth-website-icon-font";
  content: "\65";
  margin-right: 10px;
  font-size: rem($font-button-lead-font-size);
  font-weight: $font-button-lead-font-weight;
}

a.btn--icon[href*="de/"]::before {
  font-family: "luth-website-icon-font";
  content: "\61";
  margin-right: 11px;
}
.btn--icon.btn--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
}
a.btn--icon::before {
  font-family: "luth-website-icon-font";
  content: "\61";
  font-size: rem($font-button-lead-font-size);
  font-weight: $font-button-lead-font-weight;
}
a.btn--icon[href*=".localhost"]::before {
  font-family: "luth-website-icon-font";
  content: "\61";
}
.btn--center {
  display: flex;
  justify-content: center;
}
.btn--link {
  color: $color-primary !important;
  font-size: 1em;
  padding-left: 0;
  text-decoration: none;
}
.btn--link:before {
  content: '\64';
  font-family: 'feli' !important;
  font-size: 24px;
  vertical-align: middle;
  -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.btn--link:hover {
  text-decoration: none;
}
.btn--link:hover:before {
  content: '\65';
  margin-left: 4px;
  margin-right: 4px;
}
.btn--link:focus {
  border: 1px solid $color-primary;
  text-decoration: none;
}
.btn--submitselection {
  background-color: #ffffff;
  border: 2px solid $color-primary;
  color: $color-primary !important;
}
.btn--submitselection:hover,
.btn--submitselection:focus {
  background-color: $color-primary;
  border: 2px solid $color-primary-dark;
  color: $white !important;
  text-decoration: none;
}
.btn--acceptall {
  background-color: $color-success;
  border: 2px solid $color-success;
  color: #1b345e !important;
}
.btn--acceptall:hover {
  background-color: $color-success;
  border: 2px solid $color-success-dark;
  text-decoration: none;
}
.btn--acceptall:focus {
  background-color: $color-success-dark;
  border: 2px solid $color-success;
  text-decoration: none;
}
@media only screen and (min-width: 400px) {
  .btn--submitselection {
    margin-right: 35px;
  }
}
