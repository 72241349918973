.logo {
  display: block;
  margin: 0;
  width: 195px;

  img {
    margin-bottom: 0;
  }
}
.logo--footer {
  width: 170px;
}

.height--large {
  .logo--img {
    width:195px;
     height:55px;
  }
}
.height--small {
  .logo--img {
    width:195px;
    height:49px;
  }
}
@media only screen and (min-width: 768px) {
  .logo {
    width: 235px;
  }
  .height--large {
    .logo--img {
      width:325px;
       height:92px;
    }
  }
  .height--small {
    .logo--img {
      width:195px;
      height:49px;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .logo {
    width: 325px;
  }
  
}
