/* -------------------------------------------------------------
//  Colors & Fonts
// -----------------------------------------------------------*/
$black: $color-black;
$white: #ffffff;
$base-text-color: $color-black;

.bg-color-primary {
  background-color: $color-primary;
}
.bg-color-black-20{background-color: $color-dark-gray;}
    .bg-color-primary-light{
      background-color: $color-primary-bg-light;
    }
    .bg-color-primary-super-light {
      background-color: $color-primary-bg-super-light;
    }
    .bg-color-secondary-light {
      background-color: $color-secondary-bg-light;
    }
.color-accent-dark {
  color: $color-accent-dark;
}
.color-accent {
  color: $color-accent;
}
.color-accent-bg {
  color: $color-accent-bg;
}
.color-accent-subtle {
  color: $color-accent;
}
.color-danger-dark {
  color: $color-danger-dark;
}
.color-danger {
  color: $color-danger;
}
.color-danger-bg {
  color: $color-danger-bg;
}
.color-dark {
  color: $color-black;
}
.color-dark-1 {
  color: $color-black-20;
}
.color-dark-2 {
  color: $color-black-40;
}
.color-dark-3 {
  color: $color-black-60;
}
.color-info-dark {
  color: $color-info-dark;
}
.color-info {
  color: $color-info;
}
.color-info-bg {
  color: $color-info-bg;
}
.color-primary-dark {
  color: $color-primary-dark;
}
.color-primary {
  color: $color-primary;
}
.color-secondary-dark {
  color: $color-secondary-dark;
}
.color-secondary {
  color: $color-secondary;
}
.color-secondary-bg {
  color: $color-secondary-bg;
}
.color-success-dark {
  color: $color-success-dark;
}
.color-success {
  color: $color-success;
}
.color-success-bg {
  color: $color-success-bg;
}
.color-warning-dark {
  color: $color-warning-dark;
}
.color-warning {
  color: $color-warning;
}
.color-warning-bg {
  color: $color-warning-bg;
}
