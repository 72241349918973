/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/
.navwrapper {
  background: $white;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 25px;
  position: absolute;
  top: -480px;
  right: 0;
  z-index: -1;

  -webkit-transition: top $transition--slow;
  -moz-transition: top $transition--slow;
  -ms-transition: top $transition--slow;
  -o-transition: top $transition--slow;
  transition: top $transition--slow;
}
.navwrapper.active {
  top: 0;
  z-index: 10;
  padding: 0;
  .navlist__item{
    padding-left: 20px;
  }
  .navlist__item--contact {
    background: $color-secondary-dark;
    padding-top: 24px;
    padding-right: 40px;
    line-height: 34px !important;
}
}
.height--small .navwrapper.active {
  top: 0;
  z-index: 10;
  padding: 0;
  .navlist__item{
    padding-left: 20px;
  }
  .navlist__item--contact {
    background: $color-secondary-dark;
    padding-top: 24px;
    padding-right: 40px;
}
}

.navcontact {
  color: $color-black-80;
  display: flex;
  position: absolute;
  right: 50;

  &>span {
    align-items: end;
    display: grid;
    height: 20px;
    width: 26px;
    font-size: 24px;
    padding: 4px;
  }
}

.navbar {
  box-shadow: 0px 0.222rem 0.444rem rgb(0 0 0 / 15%);
  -moz-box-shadow:0px 0.222rem 0.444rem rgb(0 0 0 / 15%);
  -webkit-box-shadow:0px 0.222rem 0.444rem rgb(0 0 0 / 15%);
  // -webkit-box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.14);
  // -moz-box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.14);
  // box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.14);
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  -webkit-transition: padding $transition--slow;
  -moz-transition: padding $transition--slow;
  -ms-transition: padding $transition--slow;
  -o-transition: padding $transition--slow;
  transition: padding $transition--slow;
}
.navbar.height--large {
  padding: 20px 20px;
}
.navbar.height--small {
  padding: 6px 20px;
}
.navbutton {
  cursor: pointer;
  height: 22px;
  position: relative;

  width: 22px;
  z-index: 15;
  -webkit-transition: top $transition--slow;
  -moz-transition: top $transition--slow;
  -ms-transition: top $transition--slow;
  -o-transition: top $transition--slow;
  transition: top $transition--slow;
}

/* Mainnavigation */
.navlist {
  padding: 0;
  -webkit-transition: top $transition--slow;
  -moz-transition: top $transition--slow;
  -ms-transition: top $transition--slow;
  -o-transition: top $transition--slow;
  transition: top $transition--slow;
  background: $white;
  
    
  
}
.navlist.active {
  top: 87px;
}
.navlist__item {
  border-bottom: 1px solid $color-black-40;
  font-size: 1.11em;
  list-style: none;
}
.navlist__item:last-of-type {
  border-bottom: 0 none;
}
.navlist__item.active {
  font-weight: $font-xlarge-bold-font-weight;
}
.navlist__link {
  color: $base-text-color !important;
  display: block;
  font-family: $font-xxlarge-font-family;
  font-size: rem($font-xlarge-font-size);
  font-weight: $font-xlarge-font-weight;
  text-decoration: none;
  margin: 0;
  padding: 15px;
  position: relative;
}
.navlist__link:hover,
.navlist__link:focus {
  text-decoration: none;
}
.navlist__link.active,
.navlist__link.trail {
  font-weight: $font-xlarge-bold-font-weight;
  text-decoration: none;
}

/* Footernavigation */
.navbar.navbar--footer {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
}
.navbar--footer .navlist {
  background: transparent;
}
.navbar--footer .navlist__item {
  border: 0 none;
  font-size: 1em;
}
.navbar--footer .navlist__link {
  background: transparent;
  color: $white !important;
  font-size: 1em;
  padding: 0 0 10px 0;
}
.navbar--footer .navlist__link:hover,
.navbar--footer .navlist__link:focus {
  text-decoration: underline;
}
.sociallist__item {
  display: inline-block;
}
.footericon {
  color: $white !important;
  font-size: 0;
  line-height: 0;
  text-decoration: none !important;
}
.footericon:before {
  display: block;
  font-size: 40px;
  line-height: 1em;
}
.navlist__item--contact {
  background: $color-secondary-dark;
  padding-top: 24px;
  padding-right: 40px;
  padding-bottom: 24px;

  .navlist__link{
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    line-height: 45px;
  }
}
@media only screen and (min-width: 768px) {
  .navwrapper.active {
    top: 0;
    z-index: 10;
    padding: 0;
    .navlist__item{
      padding-left: 20px;
    }
   
  }
  .height--small .navwrapper.active {
    top: 0;
    z-index: 10;
    padding: 0;
    .navlist__item{
      padding-left: 20px;
    }
    .navlist__item--contact {
      background: $color-secondary-dark;
      padding-top: 24px;
      padding-right: 40px;
  }
  }
  .sociallist {
    margin-left: -9px;
  }
}
a.navlist__link.sibling.last.number {
  letter-spacing: normal;
  margin: 0;
  &:hover {
    letter-spacing: normal;
  }
}

@media only screen and (min-width: 1200px) {
  .navwrapper {
    background: $white;
    align-items: center;
    display: flex;
    flex-direction: row;
    left: auto;
    padding: 0;
    position: relative;
    top: auto !important;
    width: auto;
    z-index: 0;
  }
  a.navlist__link.sibling.last.number {
    letter-spacing: 1;
    margin: 0 10 0 30;
    min-width: 160px;
    &:hover {
      letter-spacing: 1;
    }
  }
  .navbar.height--large {
    padding: 19px 20px;
  }
  .navbar.height--small {
    padding: 7px 20px;
  }
  .navlist {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    //grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .navlist__item {
    display: inline;
    text-align: center;
    border-bottom: 0 none;
    border-left: 0px solid $base-text-color;
    min-width: unset;
    text-align: center;
    padding: 0 5px;
  }
  .navlist__item:first-of-type {
    border-left: 0px solid $base-text-color;
  }
  .navlist__link {
    display: inline-block;
    padding: 0;
    //margin: 0 7px 0 10px;
    font-family: $font-xxlarge-font-family;
    font-size: rem($font-xlarge-font-size);
    font-weight: $font-xlarge-font-weight;
    &:hover {
      font-weight: $font-xlarge-bold-font-weight;
    }
  }
  .navlist__link::before {
    border-top: 4px solid $color-secondary-dark;
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);

    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;

    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .navlist__link:hover::before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);

    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  .navlist__link.active::before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);

    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  .footericon:before {
    font-size: 60px;
  }
  .sociallist {
    margin-left: -15px;
  }
}

@media only screen and (min-width: 1200px) {
  .navlist__item {
    padding: 0 10px;
  }
}

/* Burgerbutton */
.line {
  background-color: $base-text-color;
  display: block;
  height: 2px;
  position: absolute;
  width: 22px;
}
.line--top {
  left: 0;
  top: 4px;
}
.line--middle {
  left: 0;
  top: 11px;
}
.line--bottom {
  left: 0px;
  top: 18px;
}
/* show Menu */
.navbutton.active .line--top {
  -webkit-animation: line-top-animation 400ms 0s 1 forwards;
  -moz-animation: line-top-animation 400ms 0s 1 forwards;
  -o-animation: line-top-animation 400ms 0s 1 forwards;
  animation: line-top-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@-moz-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@-o-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
.navbutton.active .line--bottom {
  -webkit-animation: line-bottom-animation 400ms 0s 1 forwards;
  -moz-animation: line-bottom-animation 400ms 0s 1 forwards;
  -o-animation: line-bottom-animation 400ms 0s 1 forwards;
  animation: line-bottom-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@-moz-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@-o-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
.navbutton.active .line--middle {
  -webkit-animation: line-middle-animation 400ms 0s 1 forwards;
  -moz-animation: line-middle-animation 400ms 0s 1 forwards;
  -o-animation: line-middle-animation 400ms 0s 1 forwards;
  animation: line-middle-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@-moz-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@-o-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
/* hide Menu */
.navbutton .line--top {
  -webkit-animation: line-top-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-top-animation-back 400ms 0s 1 forwards;
  -o-animation: line-top-animation-back 400ms 0s 1 forwards;
  animation: line-top-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@-moz-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@-o-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
.navbutton .line--bottom {
  -webkit-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  -o-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  animation: line-bottom-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@-moz-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@-o-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
.navbutton .line--middle {
  -webkit-animation: line-middle-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-center-animation-back 400ms 0s 1 forwards;
  -o-animation: line-center-animation-back 400ms 0s 1 forwards;
  animation: line-center-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@-moz-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@-o-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@media only screen and (min-width: 768px) {
  .navbar {
    padding: 28px;
  }
  .navbar.navbar--footer {
    padding: 0;
  }
}
// @media only screen and (min-width: 1200px) {
//   .navbar {
//     padding: 42px;
//   }
//   .navlist--footer {
//     flex-grow: initial;
//     text-align: left;
//   }
//   .navlist__item--footer {
//     display: block;
//   }
//   .navlist__item--footer .navlist__link {
//     margin-left: 0;
//   }
// }

.footernavigation {
  display: flex;
}
