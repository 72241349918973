/* -------------------------------------------------------------
//  Accordion
// -----------------------------------------------------------*/
.Accordion {
  //margin-top: 2.5rem;
  //margin-bottom:2.5rem ;
  padding: 0;
  overflow: hidden;
  min-height: 140px;
    display: grid;
}

.Accordion h2 {
  margin: auto 0;
  padding: 0;
  display: grid;
  grid-template-rows: 1fr;
  align-self: center;
  align-items: center;
}
.Accordion.focus {
  //border-color: $color-black-40;
}
.Accordion > * + * {
  //border-top: 1px solid $color-black-40;
}
.Accordion-trigger {
  background: none;
  border-style: none;
  color: $color-primary-dark;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: 1em 4em 1em 0;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;
}
.Accordion-trigger:focus,
.Accordion-trigger:hover {
  //background: $color-black-60;
  cursor: pointer;
}
.Accordion button::-moz-focus-inner {
  border: 0;
}
.Accordion-title {
  display: block;
  pointer-events: none;
  border: transparent 2px solid;
  border-radius: 5px;
  padding: 0.25em;
  outline: none;
  max-width: 825px;
  //  h2 {
  //   white-space: nowrap; 
  //   width: 70%; 
  //   overflow: hidden;
  //   text-overflow: ellipsis; 
  //  }
}
.Accordion-trigger:focus .Accordion-title {
  /*border-color: hsl(216, 94%, 73%);*/
}
.Accordion-icon {
  speak: none;
  border: 2px solid $color-primary-dark;
  border-radius: 5px;
  //display: list-item;
  height: 32px;
  pointer-events: none;
  position: absolute;
  right: 26px;
  top: 30%;
  line-height: 32px;
  /* width: 167px; */
  width: min-content;
  list-style-position: inside;
  list-style-position: inside;
  padding: 8px 16px 8px 8px;
  /* top: 10px; */
}
.Accordion-icon::before {
  background-color: $color-primary-dark;
  color:$color-primary-dark;
  content: "\6c";
  font-family: "luth-website-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  height: 0;
  width: max-content;
  //position: absolute;
  //top: 14px;
  //left: 14px;
  font-size: 150px;
}
.Accordion-icon::after {
  background-color: $color-primary-dark;
  color:$color-primary-dark;
  font-family: "luth-website-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\6d';
  display: block;
  height: 0;
  width: max-content;
  //position: absolute;
  top: 14px;
  left: 14px;
  font-size: 100px;
}
// .Accordion-icon::marker {
//   font-family: $font-button-lead-font-family;
//   background-color: $color-primary-dark;
//   color:$color-primary-dark;
  
//   content: '';
//   display: block;
//   height: 2px;
//   width: 16px;
//   position: absolute;
//   top: 23px;
//   left: 4px;
// }
.Accordion-icon::before {
 // transform: rotate(90deg);
}
.Accordion-trigger:focus .Accordion-icon,
.Accordion-trigger:hover .Accordion-icon {
}

.Accordion-trigger[aria-expanded='true'] .Accordion-icon {
  speak: none;

}
.Accordion-trigger[aria-expanded='false'] .Accordion-icon::after {
  display: none;
  //content: 'Schließen';
}
.Accordion-trigger[aria-expanded='true'] .Accordion-icon::before {
  display: none;
  //content: 'Schließen';
}
// .Accordion-trigger[aria-expanded='true'] .Accordion-icon::marker {
//   content: 'Schließen';
//   font-weight: 500 !important;
//   font-size: 20;
//   //line-height: 32;
// }
// .Accordion-trigger[aria-expanded='false'] .Accordion-icon::marker {
//   content: 'Mehr erfahren';
//   font-weight: 500 !important;
//   font-size: 20;
//  // line-height: 32;
// }
.Accordion-panel {
  margin: 0;
  padding: 1em 1.5em;
  color: $color-black-80;
  padding-top: 0;
}
.Accordion-panel[hidden] {
  display: none;
}
.Accordion-panel ul li {
  //margin-bottom: 1em;
  color: $color-black-80;
  //padding-left: 30px;
}
.Accordion-panel ul li a {
  color: $color-black-80;
  font-weight: 500;
}
.accordion--padding>div {
  padding: 20px 60px 15px 60px !important;
}
@media only screen and (min-width: 576px) {
  .Accordion {
   // margin-top: 2.5rem;
 // margin-bottom:2.5rem ;
  }
  .Accordion-icon {
    top: 30%;
  }
}
@media (max-width:1200px) {
.Accordion-title{
  max-width: 663px;
}
}
@media (max-width:990px) {
  .Accordion-title {
    display: grid;
    grid-template-columns: 1fr;

    >h2 {list-style: none;}

    .Accordion-icon {
      max-width: fit-content;
    /* margin: 0 auto; */
    right: 0;
    top:0px;
    margin-top:18px;
    }
  }
  .Accordion-title>* {
   // display: list-item;
    position: relative;
  }
  .Accordion-trigger.accordion-open {
    margin-bottom: 32px !important;
    margin-top: 32px;
    padding-top: 0;
    padding-bottom: 0;
}
}