
@font-face {
  font-family: 'Roboto';
  src: local(''),  url('../fonts/Roboto-Bold.ttf') format('truetype');
 font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Omnes';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../fonts/Omnes-SemiBold.ttf') format("truetype");
  font-display: swap;
}


@font-face {
  font-family: "luth-website-icon-font";
  src:local(''), url("../fonts/luth-website-icon-font.eot?#iefix") format("embedded-opentype"),
    url("../fonts/luth-website-icon-font.woff") format("woff"),
    url("../fonts/luth-website-icon-font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[data-icon]:before {
  font-family: "luth-website-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "luth-website-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\61";
}
.icon-arrow--left:before {
  content: "\61";
  transform: rotateY(180deg);
}
.icon-bookingcom:before {
  content: "\62";
}
.icon-close:before {
  content: "\63";
}
.icon-contact-mail:before {
  content: "\64";
}
.icon-download:before {
  content: "\65";
}
.icon-facebook:before {
  content: "\66";
}
.icon-mail:before {
  content: "\67";
}
.icon-menu:before {
  content: "\68";
}
.icon-plus:before {
  content: "\69";
}
.icon-minus:before {
  content: "\6a";
}
.icon-telephone:before {
  content: "\6b";
}
.icon-mehr-erfahren:before {
  content: "\6c";
}
.icon-schliessen:before {
  content: "\6d";
}