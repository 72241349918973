footer {
  background-color: $color-dark-gray;
  color: $white;
  padding: 44px 0;
  text-align: center;
   h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
   a {
    color: inherit;
    text-decoration: none;
}
  .icon-facebook{
    font-size: rem(36px);
    margin-right: 24px;
  }
  .icon-bookingcom{
    font-size: rem(36px);
    margin-right: 24px;
  }
  .mb-6 {
    margin-bottom: 50px !important;
  } 
  .footernavigation {
    margin-top: 18px;
    display: grid;
  gap: 6px;
}

}

.footernavigation {
  margin-top: 16px;
}
@media only screen and (min-width: 768px) {
  footer {
    padding: 50px 0 50px;
    text-align: left;
    .footernavigation {
      display: flex;
      nav {
        margin-left: -6px;
    }
     // display: grid;
      //gap: 18px;
  }
  }
}
@media only screen and (min-width: 1200px) {
  footer {
    padding: 70px 0 50px;
  }
}
