/* -------------------------------------------------------------
//  List
// -----------------------------------------------------------*/
.list {
  margin: 0;
  margin-bottom: $element-margin-bottom;
  padding: 0;

  .list__item {
    font-size: 1.125rem;
    line-height: 1.222em;
    margin: 0;
    margin-left: 1.2rem;
    margin-bottom: 0.4rem;
    padding: 0;
  }
}

li {
  font-size: rem($font-medium-font-size);
  line-height:rem($font-medium-line-height);
}
@media (min-width: 768px) {
  .list__item {
    line-height: 1.333em;
  }
}
