.btn--contact {
  background-color: $color-secondary-dark !important;
  color:$color-black-90 !important;
  padding: 21px 0 21px 0;;
  position: fixed;
  height: 90px;
  width: 90px ;
    top: 200px;
    right: 0;
    border: none !important;
    border-radius: 0;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);

}
.flap_wrapper {
  padding-top: 10px;
  /* padding-top: 2; */
  a {
    color:inherit;
    text-decoration: none;
  }
}
.contact-icon {
  text-decoration: none;
  color: $color-black-80;
  width: 24px;
  font-size: 24;
  height: 20;
  padding-right: 14;
}
.contact_open_icon {
  /* min-width: fit-content; */
 .icon-contact-mail:before {
    font-size: 43px;
  }
}
.btn.btn--primary.btn--contact.js_open_contact.active {
  align-items: flex-start;
  max-width: 350px !important;
    width: 350px;
    min-width: 350px;
    min-height: 103px;
    padding: 0;
    margin-bottom: 0;

    .contact_open_icon {
      margin-top: 16px;
      margin-left: 21px;
  }
  .close_form {
    margin-top: 19px;
    margin-right: 21px;
  }
}
.btn.btn--primary.btn--contact.js_open_contact.active > .contact_flap {
  display: grid;
  grid-template-columns: 10fr 1fr;
}
.btn--contact.active {
  width: 349px;
  height: 160px;
  display: grid !important;
  grid-template-columns: 1fr 6fr;
  min-height: 160px;
  
}
.contact_flap {
  display: none;
  .icon-close:before {
    font-size: 30px;
  }
}



/* -------------------------------------------------------------
//  Kontaktformular
// -----------------------------------------------------------*/
.contact_form {
  margin-top: 2em;
}
.contact_form .text,
.contact_form .textarea {
  border: 0 none;
  border-bottom: 1px solid #b7b7b7;
  color: #000;
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 18px;
  line-height: 1.3em;
  margin-bottom: $element-margin-bottom;
  padding: 26px 17px 26px 17px;
  width: 100%;
}
.contact_form .textarea {
  min-height: 160px;
}
.contact_form .text:focus-visible,
.contact_form .textarea:focus-visible {
  outline: 2px solid $color-primary;
}
.contact_form .widget-submit {
  text-align: center;
  padding-top: 10px;
}
.contact_form .checkbox {
  height: 20px;
  width: 20px;
  vertical-align: sub;
}
.contact_form ::-webkit-input-placeholder {
  color: #000000;
}
.contact_form ::-moz-placeholder {
  color: #000000;
}
.contact_form :-ms-input-placeholder {
  color: #000000;
}
.contact_form :-moz-placeholder {
  color: #000000;
}
.widget-text label,
.widget-textarea label {
  position: absolute;
  top: 4px;
  left: 17px;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.widget {
  position: relative;
}
.contact_form .widget.active label {
  font-size: 0.8em;
  top: 1px;
}
.contactperson__item {
  position: relative;
  max-width: 340px;
  margin: 0 auto;
  margin-bottom: 1em;
}
.checkbox_container {
  border: none;
  margin: unset;
  margin-bottom: 2rem;
  padding: unset;
}
.checkbox_container span {
  display: flex;
  justify-content: center;
}
.checkbox_container span input {
  flex-shrink: 0;
  margin-right: 10px;
}