.headline_wrapper {

    display: grid;
    grid-template-columns: 1fr;
    .headline_item {
        margin-bottom: 0;
         h2 {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }
    .item--line {
        width: 94px;
        height: 8px;
        background-color: $color-secondary-dark;
    }
}